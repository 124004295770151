<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(76.32 0 0 84.79 4164.6899 -25464.791)" gradientUnits="userSpaceOnUse" id="icon-menu_00000018213589611517887560000016505875071007412634_" x1="-52.797" x2="-53.767" y1="300.9698" y2="301.7098">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M87.3,78.8h-8.5V49.1  h-8.5v29.7h-8.5V49.1h-8.5v29.7c0,8.9,7,16.3,15.9,16.8v38.3h10.6V95.6c8.9-0.5,15.9-7.9,15.9-16.8V49.1h-8.5V78.8z M108.5,66.1V100  h10.6v33.9h10.6V49.1C118,49.1,108.5,58.6,108.5,66.1L108.5,66.1z" id="icon-menu" style="fill:url(#icon-menu_00000018213589611517887560000016505875071007412634_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>